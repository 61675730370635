import axios from "axios"
import router from "@/router";
// 创建 axios 请求实例
const serviceAxios = axios.create({
    baseURL: "/api", // 基础请求地址
    timeout: 10000, // 请求超时设置
});
// 添加请求拦截器
serviceAxios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    //新增token
    config.headers['ba-user-token'] = localStorage.getItem('token') || ''
    config.headers['server'] = true
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
serviceAxios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if(response.status !== 200) {
        console.log('请求失败')
        return Promise.reject('网络错误')
    }
    let res = response.data
    if(res.code === 1) {
        // 请求成功
        //判断是否登陆
        if(res.data && res.data.token){
            //存储token
            localStorage.setItem('token',res.data.token)
        }
        return res.data
    }else if(res.code===302){
        //请先登陆
        localStorage.removeItem('token')
        //跳转路由
        router.push('login')

    }else{
        //请求失败
        return Promise.reject(res.msg)
    }
    return res.msg
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default serviceAxios