import serviceAxios from "@/api/request";

class Screen{
    getMileageSort(){
        return serviceAxios({
            url: "screen/milegeSort",
            method: "get",
        });
    }
    getServiceInfo(){
        return serviceAxios({
            url: "screen/serviceInfo",
            method: "get",
        });
    }
    getDischargeInfo(data){
        return serviceAxios({
            url: "screen/dischargeInfo",
            method: "get",
            params: data
        });
    }
    getRunInfo(data){
        return serviceAxios({
            url: "screen/runInfo",
            method: "get",
            params: data
        });
    }
}
export default new Screen();


