//用户类api
import serviceAxios from "@/api/request";

class User{
    //登录
    login(data){
        return serviceAxios({
            url: "screen/login",
            method: "post",
            data: data
        });
    }
    // //退出登录
    // logout(){
    //     return serviceAxios({
    //         url: "user/logout",
    //         method: "post",
    //     });
    // }
    // //获取验证码
    // getCode(){
    //     return serviceAxios({
    //         url: "user/getCode",
    //         method: "get",
    //     });
    // }
}
export default new User();
